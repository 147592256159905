import React from "react"
import axios from 'axios'
import { Loading, Close, Edit, Down, Add, Remove } from '../../images'
import './index.scss'
import { arraymove, decodeEntities, encodeEntities, ausDate } from '../../utils/Functions'
import { getCurrentUser } from '../../utils/auth'
import GetInitials from '../GetInitials'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      settings: [],
      saveRequired: false,
      tempImageUpload: null,
      user:false,
      url: '',
      postId: 'create new',
      uxNumber: null,
      uxTitle: '',
      imageIndex: null,
      imageSubIndex: null,
      uxReview: {
        uxSummary: '',
        uxIntro: '',
        reviews: [{
          groupTitle: '',
          groupImage: '',
          groupSummary: '',
          findings: [{
            note: '',
            rating: '',
            date: '',
            imageRefX: '',
            imageRefY: '',
          }]
        }]
      }
    }
  }
  loadData() {
    if (this.props.splat === 'new-uxreview') {
      this.setState({
        loading: false
      })
    } else {
      const user = getCurrentUser()
      //console.log('user:',user)
      const url = `${process.env.WP_ADDRESS}/wp-json/wp/v2/uxreview?slug=${this.props.splat}&_embed`
      const settingsUrl = `${process.env.WP_ADDRESS}/wp-json/acf/v2/options`
      //console.log('settingsUrl:',settingsUrl)
      //console.log('url:',url)
      axios.all([
        axios.get(url),
        axios.get(settingsUrl)
      ])
      .then(axios.spread((res, setting) => {
        if (res.data[0]) {
          //console.log('res.data[0]:',res.data[0])
          this.setState({
            settings: setting.data.acf,
            loading: false,
            user: user,
            postId: res.data[0].id,
            uxTitle: res.data[0].title.rendered,
            uxReview: res.data[0].uxReview ? res.data[0].uxReview : this.state.uxReview,
            url: `${process.env.OKR_URL}/uxreview/${res.data[0].slug}/`,
            uxNumber: ''
          })
        }else{
          this.setState({
            loading: false
          })
        }
      }))
      .catch((err) => {
        console.log('error:', err)
        this.setState({
          loading: false,
          error: true
        })
      })
    }
  }

  componentWillMount() {
    this.loadData();
  }

  revert = (e) => {
    this.loadData();
  }

  onMouseMove = (e) => {
    const left = e.nativeEvent.offsetX
    const top = e.nativeEvent.offsetY
    const width = e.nativeEvent.srcElement.clientWidth
    const height = e.nativeEvent.srcElement.clientHeight

    const { uxReview, imageIndex, imageSubIndex } = this.state
    //const { name, value } = e.target
    if (imageSubIndex !== null && imageIndex !== null) {
      uxReview.reviews[parseInt(imageIndex)].findings[parseInt(imageSubIndex)].imageRefX = left/width*100
      uxReview.reviews[parseInt(imageIndex)].findings[parseInt(imageSubIndex)].imageRefY = top/height*100
      this.setState(uxReview)
      this.setState({saveRequired:true})
    }
  }

  imageToPinPoint = (e, index, subIndex) => {
    this.setState({imageIndex: index, imageSubIndex: subIndex})
  }

  uploadImage = (e, index) => {
    e.preventDefault()
    const { uxReview, postId, tempImageUpload } = this.state
    const user = getCurrentUser()
    this.setState({loading:true})
    const uxImageArray = {
      postId: postId,
      user: user,
      tempImageUpload: tempImageUpload
    }
    const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/uxreview?cookie=${user.cookie}`;
    //console.log('wpPostMetaURL:',wpPostMetaURL)
    var params = {
      post: uxImageArray,
    }
    axios.post(wpPostMetaURL, params)
    .then(response => {
      //console.log('response:',response.data)
      uxReview.reviews[index].groupImage = response.data.image_url
      this.setState({
        postId: response.data.post_id,
        uxReview: uxReview,
        tempImageUpload: null,
        saveRequired:true,
        loading:false
      })
      //console.log('Image State uxReview:',this.state.uxReview)
    })
    .catch(error => {
      this.setState({
        error:true,
        loading:false
      })
      console.error('submitForm Error', error)
    });
  }

  handleSubmit = (e) => {
     e.preventDefault()
     this.setState({loading:true})
     const user = getCurrentUser()
     //console.log('this.state.user:',user )
     //const user = this.state.user
     const uxReviewArray = {
       postId: this.state.postId,
       uxTitle: this.state.uxTitle,
       uxReview: this.state.uxReview,
       user: user
     }

     const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/uxreview?cookie=${user.cookie}`;
     //console.log('wpPostMetaURL:',wpPostMetaURL)
     //console.log('uxReviewArray:', uxReviewArray)
     var params = {
       post: uxReviewArray
     }
     axios.post(wpPostMetaURL, params)
     .then(response => {
       //console.log('response:',response.data)
       this.setState({
         postId: response.data.post_id,
         saveRequired:false,
         loading:false
       })
       //console.log('Save State uxReview:',this.state.uxReview)
     })
     .catch(error => {
        this.setState({
          error:true,
          loading:false
        })
        console.error('submitForm Error', error)
     });
  }

  //Form change
  handleChange = (e, index = null, subIndex = null) => {
    //e.preventDefault()
    const { uxReview, user } = this.state
    const { name, value } = e.target
    const date = Date(Date.now());
    if (subIndex !== null) {
      uxReview.reviews[parseInt(index)].findings[parseInt(subIndex)][name] = encodeEntities(value)
      uxReview.reviews[parseInt(index)].findings[parseInt(subIndex)].author = user.name
      uxReview.reviews[parseInt(index)].findings[parseInt(subIndex)].date = date
    } else if (index !== null) {
      uxReview.reviews[parseInt(index)][name] = encodeEntities(value)
    } else {
      if ( e.target.type === 'checkbox' ) {
       //console.log('Checkbox: ', name + value + uxReview[name])
       //Toggle value on off
       uxReview[name] = value
      }else{
        uxReview[name] = encodeEntities(value)
      }

    }
    this.setState(uxReview)
    this.setState({saveRequired:true})
    //console.log('onChange State uxReview:',this.state.uxReview)
  }

  addGroup = (event) => {
    event.preventDefault()
    const { uxReview } = this.state
    const newGroupTemplate = {
      groupTitle: '',
      groupImage: '',
      findings: [{
        note: '',
        rating: '',
        imageRefX: '',
        imageRefY: ''
      }]
    }
    uxReview.reviews.push(newGroupTemplate)
    this.setState({ uxReview: uxReview, saveRequired:true });
    //console.log('Add Group State uxReview:',this.state.uxReview)
  }

  removeGroup = (event, key) => {
    event.preventDefault()
    const { uxReview } = this.state
    uxReview.reviews.splice(key,1)
    this.setState({ uxReview: uxReview, saveRequired:true });
  }

  shiftGroup = (event, from, to) => {
    event.preventDefault()
    const { uxReview } = this.state
    arraymove(uxReview.reviews, from, to)
    this.setState({ uxReview: uxReview, saveRequired:true });
  }

  addFinding = (event, key) => {
    event.preventDefault()
    const { uxReview } = this.state
    const newFindingTemplate = {
      note: '',
      rating: '',
    }
    uxReview.reviews[key].findings.push(newFindingTemplate)
    this.setState({ uxReview: uxReview, saveRequired:true });
  }

  removeFinding = (event, key, subKey ) => {
    event.preventDefault()
    const { uxReview } = this.state
    uxReview.reviews[key].findings.splice(subKey,1)
    this.setState({ uxReview: uxReview, saveRequired:true });
  }

  shiftFinding = (event, key, from, to) => {
    event.preventDefault()
    const { uxReview } = this.state
    arraymove(uxReview.reviews[key].findings, from, to)
    this.setState({ uxReview: uxReview, saveRequired:true });
  }

  queueImage = (event, index) => {
    event.preventDefault()
    let tempImageUpload = {}
     //If file
     if (event.target.type === 'file') {

       //let fileName = 'tempImageUpload'
       let file = event.target.files[0];
       tempImageUpload = { data: '', file: '', key: index }

       if (file) {
          const reader = new FileReader(file);
          reader.readAsDataURL(file);
          reader.onload = () => {
            // set image and base64'd image data in component state
            tempImageUpload.data = reader.result
            tempImageUpload.file = file.name
            tempImageUpload.key = index
          }
        }
     }
     this.setState({tempImageUpload})
  }

  render() {
    const { loading, error, user, uxReview, uxTitle, postId, tempImageUpload, saveRequired, imageIndex, imageSubIndex } = this.state
    const { reviews, uxSummary, uxIntro, uxShowScore } = uxReview
    const { closeOkr, preview = false } = this.props

    var totalIssuesScore = 0
    var totalIssues = 0
    const distinctAuthors = [];
    const map = new Map();
    for (const a of reviews) {
      for (const b of a.findings) {
        totalIssuesScore = b.rating ? totalIssuesScore + parseInt(b.rating) : totalIssuesScore + 0
        totalIssues = totalIssues + 1
        if(!map.has(b.author)){
            map.set(b.author, true);
            distinctAuthors.push(b.author);
        }
      }
    }
    var totalPercent = (totalIssuesScore / (totalIssues * 10)) * 100

    const ratings = ['Personal preference', 'Low', '', '','','Medium','','','','','High' ]
    //console.log('ratings:',ratings)
    //console.log('distinctAuthors:',distinctAuthors)
    //console.log('this.state:',this.state)
    //console.log('tempImageUpload:',tempImageUpload)

    if ( error ) {
      return (
        <div className="error-msg">
          <div>
            <h4>This UX Review link has expired.</h4>
            <p>A new link can be provided by contacting the original sender.</p>
          </div>
        </div>
      )
    }

    return (
      <form
        method="post"
        onSubmit={event => this.handleSubmit(event)}
      >

      {saveRequired &&
        <div className="header-slicky">
          <span>The page needs to be saved.</span>
          <div className="page-actions">
            {loading && <div className="loading inline"><Loading/></div>}
            <button className="revert" onClick={e => this.revert(e)}>Revert</button>
            <button>Save</button>
          </div>
        </div>
      }

      <div className="uxreview">
        {preview &&
          <div className="address">
            <div className="inner">
              <span>
                Client Link: <a href={`${process.env.UX_URL}/uxreview/${this.props.splat && this.props.splat}`} rel="noopener noreferrer" target="_blank">{process.env.UX_URL}/uxreview/{this.props.splat && this.props.splat}</a> (This link will send you a slack notification when read.)
              </span>
              <button title="Close UX Review" onClick={() => closeOkr()} className="close"><Close/></button>
              <a target="_blank" rel="noopener noreferrer" href={`${process.env.WP_ADDRESS}/wp-admin/post.php?auto_login=${user.cookie}&post=${postId}&action=edit&classic-editor`} title="Edit" className="edit"><Edit/></a>
            </div>
          </div>
        }


          <div className="ux-body">
            <div className="wrap">
              {preview ?
                <div className={`field${uxTitle ? ' active' : ''}`}>
                  <label htmlFor="uxTitle">UX Title</label>
                  <input
                    type="text"
                    name="uxTitle"
                    onChange={e => this.handleChange(e)}
                    placeholder="Name your UX Review"
                    value={decodeEntities(uxTitle)}
                    required
                  />
                </div>
              :
                <div>
                  <h1>{decodeEntities(uxTitle)}</h1>
                  {distinctAuthors.length && <p className="author-list">Authors: {distinctAuthors.map((author, authIndex) => <span key={authIndex}><GetInitials name={author}/><strong>{author}</strong></span>)}</p>}
                </div>
              }

              {preview ?
              <div className={`intro field${uxIntro? ' active' : ''}`}>
                <label htmlFor="uxIntro">Intro</label>
                <textarea name="uxIntro" placeholder="Intro" onChange={e => this.handleChange(e)} value={decodeEntities(uxIntro)} />
              </div>
              :
                uxIntro &&
                <div className="intro">
                  <p dangerouslySetInnerHTML={{__html: decodeEntities(uxIntro).replace(/(\r\n|\n|\r)/gm, '<br/>')}}/>
                </div>

              }

              {postId !== 'create new' &&
              <>
              <ul className="group-list">
              {reviews && reviews.map((item, index) => (
                <li className="group-item" key={index}>

                {preview && <button className="remove" onClick={event => this.removeGroup(event, index)} title="Remove Group"><Remove/></button>}

                {preview && index > 0 && <button onClick={event => this.shiftGroup(event, index, index - 1)} className="shift-up"><Down/></button>}

                {preview && reviews.length > index +1 && <button onClick={event => this.shiftGroup(event, index, index + 1)} className="shift-down"><Down/></button>}

                {preview ?
                    <div className={`field${reviews[index].groupTitle ? ' active' : ''}`}>
                      <label htmlFor="groupTitle">Group Title</label>
                      <input
                        type="text"
                        name="groupTitle"
                        onChange={e => this.handleChange(e, index)}
                        placeholder="Group Title"
                        value={decodeEntities(reviews[index].groupTitle)}
                      />
                    </div>
                  :
                    <div><h4>{decodeEntities(reviews[index].groupTitle)}</h4></div>
                  }
                  <div className="image-with-findings">
                    <div className="image-preview">
                      <div className="scroll">
                        {reviews[index].groupImage &&
                          <div className="image" onClick={e => preview && this.onMouseMove(e)}>
                            <img src={reviews[index].groupImage} alt="Preview"/>
                            <div className="pins">
                              {item && item.findings && item.findings.map((finding, pinIndex) => finding.imageRefY > 1 && finding.imageRefX > 1 && (
                                <div className={`pin${index === imageIndex && imageSubIndex === pinIndex ? ' active' : ''} rating-${finding.rating}`} key={pinIndex} style={{top: `${finding.imageRefY}%`, left:`${finding.imageRefX}%`}}/>
                              ))}
                            </div>
                          </div>
                        }
                      </div>
                      {preview &&
                        <div className={`field${reviews[index].groupImage ? ' active' : ''}`}>
                          <label htmlFor="note">Add a preview image</label>
                          <input
                            type="hidden"
                            name="groupImage"
                            onChange={e => this.handleChange(e, index)}
                            placeholder="Image"
                            value={reviews[index].groupImage}
                          />

                          <input
                            type="file"
                            name="groupImageUpload"
                            onChange={event => this.queueImage(event, index)}
                            placeholder="Image"
                          />

                          {tempImageUpload && tempImageUpload.key === index && <button onClick={event => this.uploadImage(event, index)} className="save-image">{loading && <div className="loading inline"><Loading/></div>}Save Image</button>}

                        </div>
                      }
                    </div>
                    <div className="findings">
                      <h4>Findings</h4>
                      <ul>
                      {item && item.findings && item.findings.map((finding, subIndex) => (
                        <li key={subIndex} onMouseOver={e => this.imageToPinPoint(e, index, subIndex)} className={index === imageIndex && imageSubIndex === subIndex ? ' active' : ''}>

                          {preview && <button onClick={event => this.removeFinding(event, index, subIndex)} className="remove" title="Remove Finding"><Remove/></button>}

                          {preview && subIndex > 0 && <button onClick={event => this.shiftFinding(event, index, subIndex, subIndex - 1)} className="shift-up"><Down/></button>}

                          {preview && reviews[index].findings.length > subIndex +1 && <button onClick={event => this.shiftFinding(event, index, subIndex, subIndex + 1)} className="shift-down"><Down/></button>}

                          {reviews[index].findings[subIndex].author && <div className="author"><GetInitials name={reviews[index].findings[subIndex].author}/></div>}

                          {reviews[index].findings[subIndex].date && <span className="date">{ausDate(reviews[index].findings[subIndex].date.toString())}</span>}

                          <div className="rating" title="Importance">{reviews[index].findings[subIndex].rating && <span className={`rating-${reviews[index].findings[subIndex].rating}`}>{ratings[reviews[index].findings[subIndex].rating]}</span>}</div>

                          {preview ?
                            <div className={`field${reviews[index].findings[subIndex].note ? ' active' : ''}`}>
                              <label htmlFor="note">Note</label>
                              <textarea name="note" placeholder="Note" onChange={e => this.handleChange(e, index, subIndex)} value={decodeEntities(reviews[index].findings[subIndex].note)} />
                            </div>
                          :
                          <p dangerouslySetInnerHTML={{__html: decodeEntities(reviews[index].findings[subIndex].note).replace(/(\r\n|\n|\r)/gm, '<br/>')}}/>
                          }

                          {preview &&
                          <div className={`field${reviews[index].findings[subIndex].rating ? ' active' : ''}`}>
                            <label htmlFor="rating">Importance</label>
                            <select name="rating" placeholder="Importance" onChange={e => this.handleChange(e, index, subIndex)} value={reviews[index].findings[subIndex].rating}>
                              <option value="">Importance</option>
                              {ratings.map((rating, index) => rating && (
                                <option key={index} value={index}>{rating}</option>
                              ))}
                            </select>
                          </div>
                          }

                          {preview &&
                            <input
                              type="hidden"
                              name="imageRefX"
                              onChange={e => this.handleChange(e, index)}
                              placeholder="imageRefX"
                              value={reviews[index].findings[subIndex].imageRefX}
                            />
                          }

                          {preview &&
                            <input
                              type="hidden"
                              name="imageRefY"
                              onChange={e => this.handleChange(e, index)}
                              placeholder="imageRefY"
                              value={reviews[index].findings[subIndex].imageRefY}
                            />
                          }

                        </li>
                      ))}
                        <li>{preview && <button onClick={event => this.addFinding(event, index)} className="add" title="Add Finding"><Add/> Add Finding</button>}</li>
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
                <li className="group-item">{preview && <button onClick={event => this.addGroup(event)} className="add" title="Add Group"><Add/> Add Group</button>}</li>
              </ul>

              {preview ?
                <>
                  <div className={`field${uxShowScore ? ' active' : ''}`}>
                      <div className="checkboxes">

                        <label htmlFor="uxShowScore" className="checkbox">Show score</label>
                        <input name="uxShowScore" type="checkbox" checked={uxShowScore} onChange={e => this.handleChange(e)} value={uxShowScore === '' ? 'yes' : ''}  />
                      </div>
                  </div>
                  {totalPercent && uxShowScore && <div className="score"><p>{`Your UX review score is: ${Math.round(totalPercent)}%`}</p><p>0% - No action required<br/>100% - Action required</p></div>}
                  <div className={`field${uxSummary? ' active' : ''}`}>
                    <label htmlFor="uxSummary">Summary</label>
                    <textarea name="uxSummary" placeholder="Summary" onChange={e => this.handleChange(e)} value={decodeEntities(uxSummary)} />
                  </div>
                </>
              :
                <div>
                  <h4>Summary</h4>
                    {totalPercent && uxShowScore && <div className="score"><p>{`Your UX review score is: ${Math.round(totalPercent)}%`}</p><p>0% - No action required<br/>100% - Action required</p></div>}
                    <p dangerouslySetInnerHTML={{__html: decodeEntities(uxSummary).replace(/(\r\n|\n|\r)/gm, '<br/>')}}/>
                  </div>
              }
              </>
              }
            </div>
          </div>
        </div>
      </form>
    )
  }
}


export default Index
